// Generated automatically by nearley, version 2.15.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

// tslint:disable
// Disable all linting because the file is autogenerated (& out of our control)
const debugBlackList = new Set<string>([])
const debugWhiteList = new Set<string>([])

const toDebug = (name: string, fn?: (fields: any[]) => any) => {
    if (process.env.NODE_ENV == 'debug-parser' || debugWhiteList.has(name)) {
        // Skip debug mode for any items on the blacklist
        if (debugBlackList.has(name)) {
            return null
        }
        // return either the custom function provided, or the default one for debugging
        return fn || function (args) {
            return {type: name, args: args}
        }
    } else {
        return null // use the non-debug function
    }
}

const nuller = (a: any) => null
// const debugRule = (msg) => (a) => { debugger; console.log(msg, a); return a }
const concatChars = ([a]: string[][]) => a.join('')
const extractFirst = <T>(ary: T[][]) => ary.map(subArray => {
    if (subArray.length !== 1) {
        throw new Error(`BUG: Expected items to only have one element (usually used in listOf[...])`)
    } else {
        return subArray[0]
    }
})
const extractSecond = <T>(ary: T[][]) => ary.map(subArray => {
    if (subArray.length < 2) {
        throw new Error(`BUG: Expected items to have at least 2 elements (usually used in listOf[...])`)
    } else {
        return subArray[1]
    }
})
const extractThird = <T>(ary: T[][]) => ary.map(subArray => {
    if (subArray.length < 3) {
        throw new Error(`BUG: Expected items to have at least 3 elements (usually used in listOf[...])`)
    } else {
        return subArray[2]
    }
})

function nonemptyListOf<T>([first, rest]: any[]/*[T, T[][]]*/) {
    const f = first as T
    const r = rest as T[][]
    return [f].concat(r.map(([_1, child]) => child))
}
const upperId = ([id]: string[]) => id.toUpperCase()

const TILE_MODIFIERS = new Set([
    '...', // This one isn't a modifier but we do not allow it so that we match ellipsis rules in a different rule
    'AGAIN', // This is another hack. Some people write `[]->[AGAIN]` rather than `[]->[]AGAIN`
    'DEBUGGER', // Another hack. Ensure that this is not accidentally used as a tile name
    'NO',
    'LEFT',
    'RIGHT',
    'UP',
    'DOWN',
    'RANDOMDIR',
    'RANDOM',
    'STATIONARY',
    'MOVING',
    'ACTION',
    'VERTICAL',
    'HORIZONTAL',
    'PERPENDICULAR',
    'PARALLEL',
    'ORTHOGONAL',
    '^',
    '<',
    '>',
    'V',
])


export interface Token { value: any; [key: string]: any };

export interface Lexer {
  reset: (chunk: string, info: any) => void;
  next: () => Token | undefined;
  save: () => any;
  formatError: (token: Token) => string;
  has: (tokenType: string) => boolean
};

export interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any
};

export type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

export var Lexer: Lexer | undefined = undefined;

export var ParserRules: NearleyRule[] = [
    {"name": "main$ebnf$1", "symbols": []},
    {"name": "main$ebnf$1", "symbols": ["main$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$2", "symbols": ["main$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$3$macrocall$2", "symbols": ["t_OBJECTS"]},
    {"name": "main$ebnf$3$macrocall$3", "symbols": ["Sprite"]},
    {"name": "main$ebnf$3$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$3$macrocall$1$ebnf$1", "symbols": ["main$ebnf$3$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$3$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$3$macrocall$1$ebnf$2", "symbols": ["main$ebnf$3$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$3$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$3$macrocall$1$ebnf$3", "symbols": ["main$ebnf$3$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$3$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$3$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$3$macrocall$3"]},
    {"name": "main$ebnf$3$macrocall$1$ebnf$4", "symbols": ["main$ebnf$3$macrocall$1$ebnf$4", "main$ebnf$3$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$3$macrocall$1", "symbols": ["_", "main$ebnf$3$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$3$macrocall$2", "lineTerminator", "_", "main$ebnf$3$macrocall$1$ebnf$2", "main$ebnf$3$macrocall$1$ebnf$3", "main$ebnf$3$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$3", "symbols": ["main$ebnf$3$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$3", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$4$macrocall$2", "symbols": ["t_LEGEND"]},
    {"name": "main$ebnf$4$macrocall$3", "symbols": ["LegendTile"]},
    {"name": "main$ebnf$4$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$4$macrocall$1$ebnf$1", "symbols": ["main$ebnf$4$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$4$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$4$macrocall$1$ebnf$2", "symbols": ["main$ebnf$4$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$4$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$4$macrocall$1$ebnf$3", "symbols": ["main$ebnf$4$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$4$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$4$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$4$macrocall$3"]},
    {"name": "main$ebnf$4$macrocall$1$ebnf$4", "symbols": ["main$ebnf$4$macrocall$1$ebnf$4", "main$ebnf$4$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$4$macrocall$1", "symbols": ["_", "main$ebnf$4$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$4$macrocall$2", "lineTerminator", "_", "main$ebnf$4$macrocall$1$ebnf$2", "main$ebnf$4$macrocall$1$ebnf$3", "main$ebnf$4$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$4", "symbols": ["main$ebnf$4$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$4", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$5$macrocall$2", "symbols": ["t_SOUNDS"]},
    {"name": "main$ebnf$5$macrocall$3", "symbols": ["SoundItem"]},
    {"name": "main$ebnf$5$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$5$macrocall$1$ebnf$1", "symbols": ["main$ebnf$5$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$5$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$5$macrocall$1$ebnf$2", "symbols": ["main$ebnf$5$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$5$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$5$macrocall$1$ebnf$3", "symbols": ["main$ebnf$5$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$5$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$5$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$5$macrocall$3"]},
    {"name": "main$ebnf$5$macrocall$1$ebnf$4", "symbols": ["main$ebnf$5$macrocall$1$ebnf$4", "main$ebnf$5$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$5$macrocall$1", "symbols": ["_", "main$ebnf$5$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$5$macrocall$2", "lineTerminator", "_", "main$ebnf$5$macrocall$1$ebnf$2", "main$ebnf$5$macrocall$1$ebnf$3", "main$ebnf$5$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$5", "symbols": ["main$ebnf$5$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$5", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$6$macrocall$2", "symbols": ["t_COLLISIONLAYERS"]},
    {"name": "main$ebnf$6$macrocall$3", "symbols": ["CollisionLayerItem"]},
    {"name": "main$ebnf$6$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$6$macrocall$1$ebnf$1", "symbols": ["main$ebnf$6$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$6$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$6$macrocall$1$ebnf$2", "symbols": ["main$ebnf$6$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$6$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$6$macrocall$1$ebnf$3", "symbols": ["main$ebnf$6$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$6$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$6$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$6$macrocall$3"]},
    {"name": "main$ebnf$6$macrocall$1$ebnf$4", "symbols": ["main$ebnf$6$macrocall$1$ebnf$4", "main$ebnf$6$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$6$macrocall$1", "symbols": ["_", "main$ebnf$6$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$6$macrocall$2", "lineTerminator", "_", "main$ebnf$6$macrocall$1$ebnf$2", "main$ebnf$6$macrocall$1$ebnf$3", "main$ebnf$6$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$6", "symbols": ["main$ebnf$6$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$6", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$7$macrocall$2", "symbols": ["t_RULES"]},
    {"name": "main$ebnf$7$macrocall$3", "symbols": ["RuleItem"]},
    {"name": "main$ebnf$7$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$7$macrocall$1$ebnf$1", "symbols": ["main$ebnf$7$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$7$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$7$macrocall$1$ebnf$2", "symbols": ["main$ebnf$7$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$7$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$7$macrocall$1$ebnf$3", "symbols": ["main$ebnf$7$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$7$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$7$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$7$macrocall$3"]},
    {"name": "main$ebnf$7$macrocall$1$ebnf$4", "symbols": ["main$ebnf$7$macrocall$1$ebnf$4", "main$ebnf$7$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$7$macrocall$1", "symbols": ["_", "main$ebnf$7$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$7$macrocall$2", "lineTerminator", "_", "main$ebnf$7$macrocall$1$ebnf$2", "main$ebnf$7$macrocall$1$ebnf$3", "main$ebnf$7$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$7", "symbols": ["main$ebnf$7$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$7", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$8$macrocall$2", "symbols": ["t_WINCONDITIONS"]},
    {"name": "main$ebnf$8$macrocall$3", "symbols": ["WinConditionItem"]},
    {"name": "main$ebnf$8$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$8$macrocall$1$ebnf$1", "symbols": ["main$ebnf$8$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$8$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$8$macrocall$1$ebnf$2", "symbols": ["main$ebnf$8$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$8$macrocall$1$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "main$ebnf$8$macrocall$1$ebnf$3", "symbols": ["main$ebnf$8$macrocall$1$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$8$macrocall$1$ebnf$4", "symbols": []},
    {"name": "main$ebnf$8$macrocall$1$ebnf$4$subexpression$1", "symbols": ["main$ebnf$8$macrocall$3"]},
    {"name": "main$ebnf$8$macrocall$1$ebnf$4", "symbols": ["main$ebnf$8$macrocall$1$ebnf$4", "main$ebnf$8$macrocall$1$ebnf$4$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$8$macrocall$1", "symbols": ["_", "main$ebnf$8$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$8$macrocall$2", "lineTerminator", "_", "main$ebnf$8$macrocall$1$ebnf$2", "main$ebnf$8$macrocall$1$ebnf$3", "main$ebnf$8$macrocall$1$ebnf$4"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$8", "symbols": ["main$ebnf$8$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$8", "symbols": [], "postprocess": () => null},
    {"name": "main$ebnf$9$macrocall$2", "symbols": ["t_LEVELS"]},
    {"name": "main$ebnf$9$macrocall$3", "symbols": ["LevelItem"]},
    {"name": "main$ebnf$9$macrocall$1$ebnf$1", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$9$macrocall$1$ebnf$1", "symbols": ["main$ebnf$9$macrocall$1$ebnf$1", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$9$macrocall$1$ebnf$2", "symbols": [{"literal":"="}]},
    {"name": "main$ebnf$9$macrocall$1$ebnf$2", "symbols": ["main$ebnf$9$macrocall$1$ebnf$2", {"literal":"="}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$9$macrocall$1$ebnf$3", "symbols": []},
    {"name": "main$ebnf$9$macrocall$1$ebnf$3$subexpression$1", "symbols": ["main$ebnf$9$macrocall$3"]},
    {"name": "main$ebnf$9$macrocall$1$ebnf$3", "symbols": ["main$ebnf$9$macrocall$1$ebnf$3", "main$ebnf$9$macrocall$1$ebnf$3$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main$ebnf$9$macrocall$1", "symbols": ["_", "main$ebnf$9$macrocall$1$ebnf$1", "lineTerminator", "_", "main$ebnf$9$macrocall$2", "lineTerminator", "_", "main$ebnf$9$macrocall$1$ebnf$2", "lineTerminator", "main$ebnf$9$macrocall$1$ebnf$3"], "postprocess":  toDebug('Section', function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return {type: 'SECTION', name: name, items: extractFirst(extractFirst(items)) } }) ||
        function ([_0, _1, _2, _3, name, _5, _6, _7, _8, items]) { return extractFirst(extractFirst(items)) } },
    {"name": "main$ebnf$9", "symbols": ["main$ebnf$9$macrocall$1"], "postprocess": id},
    {"name": "main$ebnf$9", "symbols": [], "postprocess": () => null},
    {"name": "main", "symbols": ["main$ebnf$1", "_", "Title", "main$ebnf$2", "OptionalMetaData", "main$ebnf$3", "main$ebnf$4", "main$ebnf$5", "main$ebnf$6", "main$ebnf$7", "main$ebnf$8", "main$ebnf$9"], "postprocess":  toDebug('Section') ||
        function([_0, _1, title, _2, metadata, sprites, legendItems, sounds, collisionLayers, rules, winConditions, levelsAsSingleArray]) {
            const levels = []
            let currentMapLevel = null
            for (const levelRowItem of levelsAsSingleArray || []) {
                switch (levelRowItem.type) {
                    case 'LEVEL_ROW':
                        if (currentMapLevel) {
                            currentMapLevel.push(levelRowItem)
                        } else {
                            currentMapLevel = [levelRowItem]
                        }
                        break
                    case 'LEVEL_MESSAGE':
                        if (currentMapLevel) {
                            levels.push({type: 'LEVEL_MAP', rowData: currentMapLevel.map(row => row.rowData), sourceOffset: currentMapLevel[0].sourceOffset})
                            currentMapLevel = null
                        }
                        levels.push(levelRowItem)
                        break
                    case 'LEVEL_SEPARATOR':
                        if (currentMapLevel) {
                            levels.push({type: 'LEVEL_MAP', rowData: currentMapLevel.map(row => row.rowData), sourceOffset: currentMapLevel[0].sourceOffset})
                            currentMapLevel = null
                        }
                        break
                    default:
                        throw new Error(`BUG: Unsupported level row type "${levelRowItem.type}"`)
                }
            }
            // add the last level
            if (currentMapLevel) {
                levels.push({type: 'LEVEL_MAP', rowData: currentMapLevel.map(row => row.rowData), sourceOffset: currentMapLevel[0].sourceOffset})
                currentMapLevel = null
            }
            return {
                title: title.value,
                metadata: metadata.value,
                sprites: sprites || [],
                legendItems: legendItems || [],
                sounds: sounds || [],
                collisionLayers: collisionLayers || [],
                rules: rules || [],
                winConditions: winConditions || [],
                levels: levels
            }
        }
            },
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1$subexpression$1", "symbols": ["whitespaceChar"]},
    {"name": "_$ebnf$1$subexpression$1", "symbols": ["multiLineComment"]},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "_$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": toDebug('whitespace') || nuller},
    {"name": "__$ebnf$1$subexpression$1", "symbols": ["whitespaceChar"]},
    {"name": "__$ebnf$1$subexpression$1", "symbols": ["multiLineComment"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1$subexpression$1"]},
    {"name": "__$ebnf$1$subexpression$2", "symbols": ["whitespaceChar"]},
    {"name": "__$ebnf$1$subexpression$2", "symbols": ["multiLineComment"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "__$ebnf$1$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": toDebug('whitespace') || nuller},
    {"name": "multiLineComment$ebnf$1", "symbols": []},
    {"name": "multiLineComment$ebnf$1", "symbols": ["multiLineComment$ebnf$1", "textOrComment"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "multiLineComment", "symbols": [{"literal":"("}, "multiLineComment$ebnf$1", {"literal":")"}], "postprocess": toDebug('multiLineComment') || nuller},
    {"name": "textOrComment", "symbols": ["multiLineComment"], "postprocess": nuller},
    {"name": "textOrComment", "symbols": [/[^\(\)]/], "postprocess": nuller},
    {"name": "whitespaceChar", "symbols": [{"literal":" "}]},
    {"name": "whitespaceChar", "symbols": [{"literal":"\t"}]},
    {"name": "newline", "symbols": [{"literal":"\n"}]},
    {"name": "digit", "symbols": [/[0-9]/], "postprocess": id},
    {"name": "hexDigit", "symbols": [/[0-9a-fA-F]/], "postprocess": id},
    {"name": "letter", "symbols": [/[^\n \(\)]/], "postprocess": id},
    {"name": "integer$ebnf$1", "symbols": ["digit"]},
    {"name": "integer$ebnf$1", "symbols": ["integer$ebnf$1", "digit"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "integer", "symbols": ["integer$ebnf$1"], "postprocess": ([chars]) => parseInt(chars.join(''), 10)},
    {"name": "word$ebnf$1", "symbols": [/[^\n \(]/]},
    {"name": "word$ebnf$1", "symbols": ["word$ebnf$1", /[^\n \(]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "word", "symbols": ["word$ebnf$1"], "postprocess": toDebug('WORD') || concatChars},
    {"name": "words$macrocall$2", "symbols": ["word"]},
    {"name": "words$macrocall$3$ebnf$1", "symbols": ["whitespaceChar"]},
    {"name": "words$macrocall$3$ebnf$1", "symbols": ["words$macrocall$3$ebnf$1", "whitespaceChar"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "words$macrocall$3", "symbols": ["words$macrocall$3$ebnf$1"]},
    {"name": "words$macrocall$1$ebnf$1", "symbols": []},
    {"name": "words$macrocall$1$ebnf$1$subexpression$1", "symbols": ["words$macrocall$3", "words$macrocall$2"]},
    {"name": "words$macrocall$1$ebnf$1", "symbols": ["words$macrocall$1$ebnf$1", "words$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "words$macrocall$1", "symbols": ["words$macrocall$2", "words$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "words", "symbols": ["words$macrocall$1"], "postprocess": toDebug('WORDS') || function ([a]) { return extractFirst(a).join(' ') }},
    {"name": "lineTerminator", "symbols": ["_", "newline"], "postprocess": toDebug('lineTerminator') || nuller},
    {"name": "sourceCharacter", "symbols": [/[^\n ]/]},
    {"name": "nonVarChar", "symbols": ["whitespaceChar"]},
    {"name": "nonVarChar", "symbols": ["newline"]},
    {"name": "nonVarChar", "symbols": [{"literal":"["}]},
    {"name": "nonVarChar", "symbols": [{"literal":"]"}]},
    {"name": "nonVarChar", "symbols": [{"literal":"("}]},
    {"name": "nonVarChar", "symbols": [{"literal":")"}]},
    {"name": "nonVarChar", "symbols": [{"literal":"|"}]},
    {"name": "nonVarChar", "symbols": [{"literal":"."}]},
    {"name": "decimal", "symbols": ["decimalWithLeadingNumber"]},
    {"name": "decimal", "symbols": ["decimalWithLeadingPeriod"]},
    {"name": "decimalWithLeadingNumber$ebnf$1", "symbols": ["digit"]},
    {"name": "decimalWithLeadingNumber$ebnf$1", "symbols": ["decimalWithLeadingNumber$ebnf$1", "digit"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "decimalWithLeadingNumber$ebnf$2$subexpression$1$ebnf$1", "symbols": ["digit"]},
    {"name": "decimalWithLeadingNumber$ebnf$2$subexpression$1$ebnf$1", "symbols": ["decimalWithLeadingNumber$ebnf$2$subexpression$1$ebnf$1", "digit"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "decimalWithLeadingNumber$ebnf$2$subexpression$1", "symbols": [{"literal":"."}, "decimalWithLeadingNumber$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "decimalWithLeadingNumber$ebnf$2", "symbols": ["decimalWithLeadingNumber$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "decimalWithLeadingNumber$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "decimalWithLeadingNumber", "symbols": ["decimalWithLeadingNumber$ebnf$1", "decimalWithLeadingNumber$ebnf$2"], "postprocess":  ([firstDigit, rest]) => {
            if (rest) {
                return Number.parseFloat(`${firstDigit[0]}.${rest[1].join('')}`)
            } else {
                return Number.parseInt(firstDigit[0], 10)
            }
        } },
    {"name": "decimalWithLeadingPeriod$ebnf$1", "symbols": ["digit"]},
    {"name": "decimalWithLeadingPeriod$ebnf$1", "symbols": ["decimalWithLeadingPeriod$ebnf$1", "digit"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "decimalWithLeadingPeriod", "symbols": [{"literal":"."}, "decimalWithLeadingPeriod$ebnf$1"], "postprocess": ([_1, digits]) => { return Number.parseInt(digits.join(''), 10) }},
    {"name": "colorHex6", "symbols": [{"literal":"#"}, "hexDigit", "hexDigit", "hexDigit", "hexDigit", "hexDigit", "hexDigit"], "postprocess": (a, sourceOffset) => { return {type:'COLOR_HEX6', value: a.join(''), sourceOffset} }},
    {"name": "colorHex3", "symbols": [{"literal":"#"}, "hexDigit", "hexDigit", "hexDigit"], "postprocess": (a, sourceOffset) => { return {type:'COLOR_HEX3', value: a.join(''), sourceOffset} }},
    {"name": "colorNameOrHex", "symbols": ["colorHex6"], "postprocess": id},
    {"name": "colorNameOrHex", "symbols": ["colorHex3"], "postprocess": id},
    {"name": "colorNameOrHex", "symbols": ["colorName"], "postprocess": id},
    {"name": "colorName", "symbols": [/[^\n #\(0-9\.]/, "word"], "postprocess": toDebug('COLOR_NAME') || function ([first, rest], sourceOffset) { return {type:'COLOR_NAME', value: [first].concat(rest).join(''), sourceOffset} }},
    {"name": "legendVariableChar", "symbols": [/[^\n\ \=]/]},
    {"name": "ruleVariableChar", "symbols": [/[^(?=\.\.\.)\n \=\[\]\|]/]},
    {"name": "ruleVariableName$ebnf$1", "symbols": ["ruleVariableChar"]},
    {"name": "ruleVariableName$ebnf$1", "symbols": ["ruleVariableName$ebnf$1", "ruleVariableChar"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "ruleVariableName", "symbols": ["ruleVariableName$ebnf$1"], "postprocess": concatChars},
    {"name": "lookupRuleVariableName$ebnf$1", "symbols": [/[^\n \=\[\]\|]/]},
    {"name": "lookupRuleVariableName$ebnf$1", "symbols": ["lookupRuleVariableName$ebnf$1", /[^\n \=\[\]\|]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "lookupRuleVariableName", "symbols": ["lookupRuleVariableName$ebnf$1"], "postprocess":  ([a], offset, reject) => {
          const str = a.join('')
          if (TILE_MODIFIERS.has(str.toUpperCase())) {
            return reject
          } else {
            return str
          }
        } },
    {"name": "collisionVariableChar", "symbols": [/[^(?=\.\.\.)\ \n\=\[\]\|\,]/]},
    {"name": "collisionVariableName$ebnf$1", "symbols": ["collisionVariableChar"]},
    {"name": "collisionVariableName$ebnf$1", "symbols": ["collisionVariableName$ebnf$1", "collisionVariableChar"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "collisionVariableName", "symbols": ["collisionVariableName$ebnf$1"], "postprocess": concatChars},
    {"name": "lookupCollisionVariableName", "symbols": ["collisionVariableName"], "postprocess": id},
    {"name": "t_DEBUGGER", "symbols": ["t_DEBUGGER_ADD"], "postprocess": id},
    {"name": "t_DEBUGGER", "symbols": ["t_DEBUGGER_REMOVE"], "postprocess": id},
    {"name": "t_DEBUGGER", "symbols": ["t_DEBUGGER_DEFAULT"], "postprocess": id},
    {"name": "t_DEBUGGER_DEFAULT$subexpression$1", "symbols": [/[dD]/, /[eE]/, /[bB]/, /[uU]/, /[gG]/, /[gG]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_DEBUGGER_DEFAULT", "symbols": ["t_DEBUGGER_DEFAULT$subexpression$1"], "postprocess": upperId},
    {"name": "t_DEBUGGER_ADD$subexpression$1", "symbols": [/[dD]/, /[eE]/, /[bB]/, /[uU]/, /[gG]/, /[gG]/, /[eE]/, /[rR]/, {"literal":"_"}, /[aA]/, /[dD]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_DEBUGGER_ADD", "symbols": ["t_DEBUGGER_ADD$subexpression$1"], "postprocess": upperId},
    {"name": "t_DEBUGGER_REMOVE$subexpression$1", "symbols": [/[dD]/, /[eE]/, /[bB]/, /[uU]/, /[gG]/, /[gG]/, /[eE]/, /[rR]/, {"literal":"_"}, /[rR]/, /[eE]/, /[mM]/, /[oO]/, /[vV]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_DEBUGGER_REMOVE", "symbols": ["t_DEBUGGER_REMOVE$subexpression$1"], "postprocess": upperId},
    {"name": "t_OBJECTS$subexpression$1", "symbols": [/[oO]/, /[bB]/, /[jJ]/, /[eE]/, /[cC]/, /[tT]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_OBJECTS", "symbols": ["t_OBJECTS$subexpression$1"], "postprocess": upperId},
    {"name": "t_LEGEND$subexpression$1", "symbols": [/[lL]/, /[eE]/, /[gG]/, /[eE]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_LEGEND", "symbols": ["t_LEGEND$subexpression$1"], "postprocess": upperId},
    {"name": "t_SOUNDS$subexpression$1", "symbols": [/[sS]/, /[oO]/, /[uU]/, /[nN]/, /[dD]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SOUNDS", "symbols": ["t_SOUNDS$subexpression$1"], "postprocess": upperId},
    {"name": "t_COLLISIONLAYERS$subexpression$1", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[lL]/, /[iI]/, /[sS]/, /[iI]/, /[oO]/, /[nN]/, /[lL]/, /[aA]/, /[yY]/, /[eE]/, /[rR]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_COLLISIONLAYERS", "symbols": ["t_COLLISIONLAYERS$subexpression$1"], "postprocess": upperId},
    {"name": "t_RULES$subexpression$1", "symbols": [/[rR]/, /[uU]/, /[lL]/, /[eE]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RULES", "symbols": ["t_RULES$subexpression$1"], "postprocess": upperId},
    {"name": "t_WINCONDITIONS$subexpression$1", "symbols": [/[wW]/, /[iI]/, /[nN]/, /[cC]/, /[oO]/, /[nN]/, /[dD]/, /[iI]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_WINCONDITIONS", "symbols": ["t_WINCONDITIONS$subexpression$1"], "postprocess": upperId},
    {"name": "t_LEVELS$subexpression$1", "symbols": [/[lL]/, /[eE]/, /[vV]/, /[eE]/, /[lL]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_LEVELS", "symbols": ["t_LEVELS$subexpression$1"], "postprocess": upperId},
    {"name": "t_RIGID$subexpression$1", "symbols": [/[rR]/, /[iI]/, /[gG]/, /[iI]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RIGID", "symbols": ["t_RIGID$subexpression$1"], "postprocess": upperId},
    {"name": "t_LATE$subexpression$1", "symbols": [/[lL]/, /[aA]/, /[tT]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_LATE", "symbols": ["t_LATE$subexpression$1"], "postprocess": upperId},
    {"name": "t_RANDOM$subexpression$1", "symbols": [/[rR]/, /[aA]/, /[nN]/, /[dD]/, /[oO]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RANDOM", "symbols": ["t_RANDOM$subexpression$1"], "postprocess": upperId},
    {"name": "t_RANDOMDIR$subexpression$1", "symbols": [/[rR]/, /[aA]/, /[nN]/, /[dD]/, /[oO]/, /[mM]/, /[dD]/, /[iI]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RANDOMDIR", "symbols": ["t_RANDOMDIR$subexpression$1"], "postprocess": upperId},
    {"name": "t_ACTION$subexpression$1", "symbols": [/[aA]/, /[cC]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ACTION", "symbols": ["t_ACTION$subexpression$1"], "postprocess": upperId},
    {"name": "t_STARTLOOP$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/, /[lL]/, /[oO]/, /[oO]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_STARTLOOP", "symbols": ["t_STARTLOOP$subexpression$1"], "postprocess": upperId},
    {"name": "t_ENDLOOP$subexpression$1", "symbols": [/[eE]/, /[nN]/, /[dD]/, /[lL]/, /[oO]/, /[oO]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ENDLOOP", "symbols": ["t_ENDLOOP$subexpression$1"], "postprocess": upperId},
    {"name": "t_UP$subexpression$1", "symbols": [/[uU]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_UP", "symbols": ["t_UP$subexpression$1"], "postprocess": upperId},
    {"name": "t_DOWN$subexpression$1", "symbols": [/[dD]/, /[oO]/, /[wW]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_DOWN", "symbols": ["t_DOWN$subexpression$1"], "postprocess": upperId},
    {"name": "t_LEFT$subexpression$1", "symbols": [/[lL]/, /[eE]/, /[fF]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_LEFT", "symbols": ["t_LEFT$subexpression$1"], "postprocess": upperId},
    {"name": "t_RIGHT$subexpression$1", "symbols": [/[rR]/, /[iI]/, /[gG]/, /[hH]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RIGHT", "symbols": ["t_RIGHT$subexpression$1"], "postprocess": upperId},
    {"name": "t_ARROW_UP$subexpression$1", "symbols": [{"literal":"^"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ARROW_UP", "symbols": ["t_ARROW_UP$subexpression$1"], "postprocess": upperId},
    {"name": "t_ARROW_DOWN$subexpression$1", "symbols": [/[vV]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ARROW_DOWN", "symbols": ["t_ARROW_DOWN$subexpression$1"], "postprocess": upperId},
    {"name": "t_ARROW_LEFT$subexpression$1", "symbols": [{"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ARROW_LEFT", "symbols": ["t_ARROW_LEFT$subexpression$1"], "postprocess": upperId},
    {"name": "t_ARROW_RIGHT$subexpression$1", "symbols": [{"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ARROW_RIGHT", "symbols": ["t_ARROW_RIGHT$subexpression$1"], "postprocess": upperId},
    {"name": "t_MOVING$subexpression$1", "symbols": [/[mM]/, /[oO]/, /[vV]/, /[iI]/, /[nN]/, /[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_MOVING", "symbols": ["t_MOVING$subexpression$1"], "postprocess": upperId},
    {"name": "t_ORTHOGONAL$subexpression$1", "symbols": [/[oO]/, /[rR]/, /[tT]/, /[hH]/, /[oO]/, /[gG]/, /[oO]/, /[nN]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ORTHOGONAL", "symbols": ["t_ORTHOGONAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_PERPENDICULAR$subexpression$1", "symbols": [/[pP]/, /[eE]/, /[rR]/, /[pP]/, /[eE]/, /[nN]/, /[dD]/, /[iI]/, /[cC]/, /[uU]/, /[lL]/, /[aA]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_PERPENDICULAR", "symbols": ["t_PERPENDICULAR$subexpression$1"], "postprocess": upperId},
    {"name": "t_PARALLEL$subexpression$1", "symbols": [/[pP]/, /[aA]/, /[rR]/, /[aA]/, /[lL]/, /[lL]/, /[eE]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_PARALLEL", "symbols": ["t_PARALLEL$subexpression$1"], "postprocess": upperId},
    {"name": "t_STATIONARY$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/, /[aA]/, /[rR]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_STATIONARY", "symbols": ["t_STATIONARY$subexpression$1"], "postprocess": upperId},
    {"name": "t_HORIZONTAL$subexpression$1", "symbols": [/[hH]/, /[oO]/, /[rR]/, /[iI]/, /[zZ]/, /[oO]/, /[nN]/, /[tT]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_HORIZONTAL", "symbols": ["t_HORIZONTAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_VERTICAL$subexpression$1", "symbols": [/[vV]/, /[eE]/, /[rR]/, /[tT]/, /[iI]/, /[cC]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_VERTICAL", "symbols": ["t_VERTICAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_ARROW_ANY", "symbols": ["t_ARROW_UP"], "postprocess": upperId},
    {"name": "t_ARROW_ANY", "symbols": ["t_ARROW_DOWN"], "postprocess": upperId},
    {"name": "t_ARROW_ANY", "symbols": ["t_ARROW_LEFT"], "postprocess": upperId},
    {"name": "t_ARROW_ANY", "symbols": ["t_ARROW_RIGHT"], "postprocess": upperId},
    {"name": "t_AGAIN$subexpression$1", "symbols": [/[aA]/, /[gG]/, /[aA]/, /[iI]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_AGAIN", "symbols": ["t_AGAIN$subexpression$1"], "postprocess": upperId},
    {"name": "t_CANCEL$subexpression$1", "symbols": [/[cC]/, /[aA]/, /[nN]/, /[cC]/, /[eE]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_CANCEL", "symbols": ["t_CANCEL$subexpression$1"], "postprocess": upperId},
    {"name": "t_CHECKPOINT$subexpression$1", "symbols": [/[cC]/, /[hH]/, /[eE]/, /[cC]/, /[kK]/, /[pP]/, /[oO]/, /[iI]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_CHECKPOINT", "symbols": ["t_CHECKPOINT$subexpression$1"], "postprocess": upperId},
    {"name": "t_RESTART$subexpression$1", "symbols": [/[rR]/, /[eE]/, /[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RESTART", "symbols": ["t_RESTART$subexpression$1"], "postprocess": upperId},
    {"name": "t_UNDO$subexpression$1", "symbols": [/[uU]/, /[nN]/, /[dD]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_UNDO", "symbols": ["t_UNDO$subexpression$1"], "postprocess": upperId},
    {"name": "t_WIN$subexpression$1", "symbols": [/[wW]/, /[iI]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_WIN", "symbols": ["t_WIN$subexpression$1"], "postprocess": upperId},
    {"name": "t_MESSAGE$subexpression$1", "symbols": [/[mM]/, /[eE]/, /[sS]/, /[sS]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_MESSAGE", "symbols": ["t_MESSAGE$subexpression$1"], "postprocess": upperId},
    {"name": "t_ELLIPSIS$subexpression$1", "symbols": [{"literal":"."}, {"literal":"."}, {"literal":"."}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ELLIPSIS", "symbols": ["t_ELLIPSIS$subexpression$1"], "postprocess": upperId},
    {"name": "t_AND$subexpression$1", "symbols": [/[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_AND", "symbols": ["t_AND$subexpression$1"], "postprocess": upperId},
    {"name": "t_OR$subexpression$1", "symbols": [/[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_OR", "symbols": ["t_OR$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX0$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"0"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX0", "symbols": ["t_SFX0$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX1$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"1"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX1", "symbols": ["t_SFX1$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX2$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX2", "symbols": ["t_SFX2$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX3$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"3"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX3", "symbols": ["t_SFX3$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX4$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"4"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX4", "symbols": ["t_SFX4$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX5$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"5"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX5", "symbols": ["t_SFX5$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX6$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"6"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX6", "symbols": ["t_SFX6$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX7$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"7"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX7", "symbols": ["t_SFX7$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX8$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"8"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX8", "symbols": ["t_SFX8$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX9$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"9"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX9", "symbols": ["t_SFX9$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX10$subexpression$1", "symbols": [/[sS]/, /[fF]/, /[xX]/, {"literal":"1"}, {"literal":"0"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SFX10", "symbols": ["t_SFX10$subexpression$1"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX10"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX0"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX1"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX2"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX3"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX4"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX5"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX6"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX7"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX8"], "postprocess": upperId},
    {"name": "t_SFX", "symbols": ["t_SFX9"], "postprocess": upperId},
    {"name": "t_TITLE$subexpression$1", "symbols": [/[tT]/, /[iI]/, /[tT]/, /[lL]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_TITLE", "symbols": ["t_TITLE$subexpression$1"], "postprocess": upperId},
    {"name": "t_AUTHOR$subexpression$1", "symbols": [/[aA]/, /[uU]/, /[tT]/, /[hH]/, /[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_AUTHOR", "symbols": ["t_AUTHOR$subexpression$1"], "postprocess": upperId},
    {"name": "t_HOMEPAGE$subexpression$1", "symbols": [/[hH]/, /[oO]/, /[mM]/, /[eE]/, /[pP]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_HOMEPAGE", "symbols": ["t_HOMEPAGE$subexpression$1"], "postprocess": upperId},
    {"name": "t_YOUTUBE$subexpression$1", "symbols": [/[yY]/, /[oO]/, /[uU]/, /[tT]/, /[uU]/, /[bB]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_YOUTUBE", "symbols": ["t_YOUTUBE$subexpression$1"], "postprocess": upperId},
    {"name": "t_ZOOMSCREEN$subexpression$1", "symbols": [/[zZ]/, /[oO]/, /[oO]/, /[mM]/, /[sS]/, /[cC]/, /[rR]/, /[eE]/, /[eE]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ZOOMSCREEN", "symbols": ["t_ZOOMSCREEN$subexpression$1"], "postprocess": upperId},
    {"name": "t_FLICKSCREEN$subexpression$1", "symbols": [/[fF]/, /[lL]/, /[iI]/, /[cC]/, /[kK]/, /[sS]/, /[cC]/, /[rR]/, /[eE]/, /[eE]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_FLICKSCREEN", "symbols": ["t_FLICKSCREEN$subexpression$1"], "postprocess": upperId},
    {"name": "t_REQUIRE_PLAYER_MOVEMENT$subexpression$1", "symbols": [/[rR]/, /[eE]/, /[qQ]/, /[uU]/, /[iI]/, /[rR]/, /[eE]/, {"literal":"_"}, /[pP]/, /[lL]/, /[aA]/, /[yY]/, /[eE]/, /[rR]/, {"literal":"_"}, /[mM]/, /[oO]/, /[vV]/, /[eE]/, /[mM]/, /[eE]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_REQUIRE_PLAYER_MOVEMENT", "symbols": ["t_REQUIRE_PLAYER_MOVEMENT$subexpression$1"], "postprocess": upperId},
    {"name": "t_RUN_RULES_ON_LEVEL_START$subexpression$1", "symbols": [/[rR]/, /[uU]/, /[nN]/, {"literal":"_"}, /[rR]/, /[uU]/, /[lL]/, /[eE]/, /[sS]/, {"literal":"_"}, /[oO]/, /[nN]/, {"literal":"_"}, /[lL]/, /[eE]/, /[vV]/, /[eE]/, /[lL]/, {"literal":"_"}, /[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_RUN_RULES_ON_LEVEL_START", "symbols": ["t_RUN_RULES_ON_LEVEL_START$subexpression$1"], "postprocess": upperId},
    {"name": "t_COLOR_PALETTE$subexpression$1", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/, {"literal":"_"}, /[pP]/, /[aA]/, /[lL]/, /[eE]/, /[tT]/, /[tT]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_COLOR_PALETTE", "symbols": ["t_COLOR_PALETTE$subexpression$1"], "postprocess": upperId},
    {"name": "t_BACKGROUND_COLOR$subexpression$1", "symbols": [/[bB]/, /[aA]/, /[cC]/, /[kK]/, /[gG]/, /[rR]/, /[oO]/, /[uU]/, /[nN]/, /[dD]/, {"literal":"_"}, /[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_BACKGROUND_COLOR", "symbols": ["t_BACKGROUND_COLOR$subexpression$1"], "postprocess": upperId},
    {"name": "t_TEXT_COLOR$subexpression$1", "symbols": [/[tT]/, /[eE]/, /[xX]/, /[tT]/, {"literal":"_"}, /[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_TEXT_COLOR", "symbols": ["t_TEXT_COLOR$subexpression$1"], "postprocess": upperId},
    {"name": "t_REALTIME_INTERVAL$subexpression$1", "symbols": [/[rR]/, /[eE]/, /[aA]/, /[lL]/, /[tT]/, /[iI]/, /[mM]/, /[eE]/, {"literal":"_"}, /[iI]/, /[nN]/, /[tT]/, /[eE]/, /[rR]/, /[vV]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_REALTIME_INTERVAL", "symbols": ["t_REALTIME_INTERVAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_KEY_REPEAT_INTERVAL$subexpression$1", "symbols": [/[kK]/, /[eE]/, /[yY]/, {"literal":"_"}, /[rR]/, /[eE]/, /[pP]/, /[eE]/, /[aA]/, /[tT]/, {"literal":"_"}, /[iI]/, /[nN]/, /[tT]/, /[eE]/, /[rR]/, /[vV]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_KEY_REPEAT_INTERVAL", "symbols": ["t_KEY_REPEAT_INTERVAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_AGAIN_INTERVAL$subexpression$1", "symbols": [/[aA]/, /[gG]/, /[aA]/, /[iI]/, /[nN]/, {"literal":"_"}, /[iI]/, /[nN]/, /[tT]/, /[eE]/, /[rR]/, /[vV]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_AGAIN_INTERVAL", "symbols": ["t_AGAIN_INTERVAL$subexpression$1"], "postprocess": upperId},
    {"name": "t_NOACTION$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[aA]/, /[cC]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_NOACTION", "symbols": ["t_NOACTION$subexpression$1"], "postprocess": upperId},
    {"name": "t_NOUNDO$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[uU]/, /[nN]/, /[dD]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_NOUNDO", "symbols": ["t_NOUNDO$subexpression$1"], "postprocess": upperId},
    {"name": "t_NORESTART$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[rR]/, /[eE]/, /[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_NORESTART", "symbols": ["t_NORESTART$subexpression$1"], "postprocess": upperId},
    {"name": "t_THROTTLE_MOVEMENT$subexpression$1", "symbols": [/[tT]/, /[hH]/, /[rR]/, /[oO]/, /[tT]/, /[tT]/, /[lL]/, /[eE]/, {"literal":"_"}, /[mM]/, /[oO]/, /[vV]/, /[eE]/, /[mM]/, /[eE]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_THROTTLE_MOVEMENT", "symbols": ["t_THROTTLE_MOVEMENT$subexpression$1"], "postprocess": upperId},
    {"name": "t_NOREPEAT_ACTION$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[rR]/, /[eE]/, /[pP]/, /[eE]/, /[aA]/, /[tT]/, {"literal":"_"}, /[aA]/, /[cC]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_NOREPEAT_ACTION", "symbols": ["t_NOREPEAT_ACTION$subexpression$1"], "postprocess": upperId},
    {"name": "t_VERBOSE_LOGGING$subexpression$1", "symbols": [/[vV]/, /[eE]/, /[rR]/, /[bB]/, /[oO]/, /[sS]/, /[eE]/, {"literal":"_"}, /[lL]/, /[oO]/, /[gG]/, /[gG]/, /[iI]/, /[nN]/, /[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_VERBOSE_LOGGING", "symbols": ["t_VERBOSE_LOGGING$subexpression$1"], "postprocess": upperId},
    {"name": "t_TRANSPARENT$subexpression$1", "symbols": [/[tT]/, /[rR]/, /[aA]/, /[nN]/, /[sS]/, /[pP]/, /[aA]/, /[rR]/, /[eE]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_TRANSPARENT", "symbols": ["t_TRANSPARENT$subexpression$1"], "postprocess": upperId},
    {"name": "t_MOVE$subexpression$1", "symbols": [/[mM]/, /[oO]/, /[vV]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_MOVE", "symbols": ["t_MOVE$subexpression$1"], "postprocess": upperId},
    {"name": "t_DESTROY$subexpression$1", "symbols": [/[dD]/, /[eE]/, /[sS]/, /[tT]/, /[rR]/, /[oO]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_DESTROY", "symbols": ["t_DESTROY$subexpression$1"], "postprocess": upperId},
    {"name": "t_CREATE$subexpression$1", "symbols": [/[cC]/, /[rR]/, /[eE]/, /[aA]/, /[tT]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_CREATE", "symbols": ["t_CREATE$subexpression$1"], "postprocess": upperId},
    {"name": "t_CANTMOVE$subexpression$1", "symbols": [/[cC]/, /[aA]/, /[nN]/, /[tT]/, /[mM]/, /[oO]/, /[vV]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_CANTMOVE", "symbols": ["t_CANTMOVE$subexpression$1"], "postprocess": upperId},
    {"name": "t_TITLESCREEN$subexpression$1", "symbols": [/[tT]/, /[iI]/, /[tT]/, /[lL]/, /[eE]/, /[sS]/, /[cC]/, /[rR]/, /[eE]/, /[eE]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_TITLESCREEN", "symbols": ["t_TITLESCREEN$subexpression$1"], "postprocess": upperId},
    {"name": "t_STARTGAME$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/, /[gG]/, /[aA]/, /[mM]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_STARTGAME", "symbols": ["t_STARTGAME$subexpression$1"], "postprocess": upperId},
    {"name": "t_STARTLEVEL$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[rR]/, /[tT]/, /[lL]/, /[eE]/, /[vV]/, /[eE]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_STARTLEVEL", "symbols": ["t_STARTLEVEL$subexpression$1"], "postprocess": upperId},
    {"name": "t_ENDLEVEL$subexpression$1", "symbols": [/[eE]/, /[nN]/, /[dD]/, /[lL]/, /[eE]/, /[vV]/, /[eE]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ENDLEVEL", "symbols": ["t_ENDLEVEL$subexpression$1"], "postprocess": upperId},
    {"name": "t_ENDGAME$subexpression$1", "symbols": [/[eE]/, /[nN]/, /[dD]/, /[gG]/, /[aA]/, /[mM]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ENDGAME", "symbols": ["t_ENDGAME$subexpression$1"], "postprocess": upperId},
    {"name": "t_SHOWMESSAGE$subexpression$1", "symbols": [/[sS]/, /[hH]/, /[oO]/, /[wW]/, /[mM]/, /[eE]/, /[sS]/, /[sS]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SHOWMESSAGE", "symbols": ["t_SHOWMESSAGE$subexpression$1"], "postprocess": upperId},
    {"name": "t_CLOSEMESSAGE$subexpression$1", "symbols": [/[cC]/, /[lL]/, /[oO]/, /[sS]/, /[eE]/, /[mM]/, /[eE]/, /[sS]/, /[sS]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_CLOSEMESSAGE", "symbols": ["t_CLOSEMESSAGE$subexpression$1"], "postprocess": upperId},
    {"name": "t_GROUP_RULE_PLUS$subexpression$1", "symbols": [{"literal":"+"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_GROUP_RULE_PLUS", "symbols": ["t_GROUP_RULE_PLUS$subexpression$1"], "postprocess": upperId},
    {"name": "t_ON$subexpression$1", "symbols": [/[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ON", "symbols": ["t_ON$subexpression$1"], "postprocess": upperId},
    {"name": "t_NO$subexpression$1", "symbols": [/[nN]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_NO", "symbols": ["t_NO$subexpression$1"], "postprocess": upperId},
    {"name": "t_ALL$subexpression$1", "symbols": [/[aA]/, /[lL]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ALL", "symbols": ["t_ALL$subexpression$1"], "postprocess": upperId},
    {"name": "t_ANY$subexpression$1", "symbols": [/[aA]/, /[nN]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_ANY", "symbols": ["t_ANY$subexpression$1"], "postprocess": upperId},
    {"name": "t_SOME$subexpression$1", "symbols": [/[sS]/, /[oO]/, /[mM]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "t_SOME", "symbols": ["t_SOME$subexpression$1"], "postprocess": upperId},
    {"name": "Title", "symbols": ["t_TITLE", "__", "words"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'TITLE', value, sourceOffset} }},
    {"name": "OptionalMetaData$ebnf$1", "symbols": []},
    {"name": "OptionalMetaData$ebnf$1$subexpression$1$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "OptionalMetaData$ebnf$1$subexpression$1$ebnf$1", "symbols": ["OptionalMetaData$ebnf$1$subexpression$1$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "OptionalMetaData$ebnf$1$subexpression$1", "symbols": ["_", "OptionalMetaDataItem", "OptionalMetaData$ebnf$1$subexpression$1$ebnf$1"]},
    {"name": "OptionalMetaData$ebnf$1", "symbols": ["OptionalMetaData$ebnf$1", "OptionalMetaData$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "OptionalMetaData", "symbols": ["OptionalMetaData$ebnf$1"], "postprocess": ([vals]) => { return {type: 'METADATA', value: extractSecond(vals)} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_AUTHOR", "__", "words"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'AUTHOR', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_HOMEPAGE", "__", "word"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'HOMEPAGE', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_YOUTUBE", "__", "word"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'YOUTUBE', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_ZOOMSCREEN", "__", "widthAndHeight"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'ZOOMSCREEN', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_FLICKSCREEN", "__", "widthAndHeight"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'FLICKSCREEN', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem$ebnf$1$subexpression$1$string$1", "symbols": [{"literal":"o"}, {"literal":"f"}, {"literal":"f"}], "postprocess": (d) => d.join('')},
    {"name": "OptionalMetaDataItem$ebnf$1$subexpression$1", "symbols": ["__", "OptionalMetaDataItem$ebnf$1$subexpression$1$string$1"]},
    {"name": "OptionalMetaDataItem$ebnf$1", "symbols": ["OptionalMetaDataItem$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "OptionalMetaDataItem$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "OptionalMetaDataItem", "symbols": ["t_REQUIRE_PLAYER_MOVEMENT", "OptionalMetaDataItem$ebnf$1"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'REQUIRE_PLAYER_MOVEMENT', value: !!value, sourceOffset} }},
    {"name": "OptionalMetaDataItem$ebnf$2$subexpression$1$string$1", "symbols": [{"literal":"t"}, {"literal":"r"}, {"literal":"u"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "OptionalMetaDataItem$ebnf$2$subexpression$1", "symbols": ["__", "OptionalMetaDataItem$ebnf$2$subexpression$1$string$1"]},
    {"name": "OptionalMetaDataItem$ebnf$2", "symbols": ["OptionalMetaDataItem$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "OptionalMetaDataItem$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "OptionalMetaDataItem", "symbols": ["t_RUN_RULES_ON_LEVEL_START", "OptionalMetaDataItem$ebnf$2"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'RUN_RULES_ON_LEVEL_START', value: true, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_COLOR_PALETTE", "__", "word"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'COLOR_PALETTE', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_BACKGROUND_COLOR", "__", "colorNameOrHex"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'BACKGROUND_COLOR', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_TEXT_COLOR", "__", "colorNameOrHex"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'TEXT_COLOR', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_REALTIME_INTERVAL", "__", "decimal"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'REALTIME_INTERVAL', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_KEY_REPEAT_INTERVAL", "__", "decimal"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'KEY_REPEAT_INTERVAL', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_AGAIN_INTERVAL", "__", "decimal"], "postprocess": ([_1, _2, value], sourceOffset) => { return {type:'AGAIN_INTERVAL', value, sourceOffset} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_NOACTION"], "postprocess": () => { return {type:'NOACTION', value: true} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_NOUNDO"], "postprocess": () => { return {type:'NOUNDO', value: true} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_NOREPEAT_ACTION"], "postprocess": () => { return {type:'NOREPEAT_ACTION', value: true} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_THROTTLE_MOVEMENT"], "postprocess": () => { return {type:'THROTTLE_MOVEMENT', value: true} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_NORESTART"], "postprocess": () => { return {type:'NORESTART', value: true} }},
    {"name": "OptionalMetaDataItem", "symbols": ["t_VERBOSE_LOGGING"], "postprocess": () => { return {type:'VERBOSE_LOGGING', value: true} }},
    {"name": "widthAndHeight", "symbols": ["integer", {"literal":"x"}, "integer"], "postprocess": ([width, _1, height]) => { return {type: 'WIDTH_AND_HEIGHT', width, height} }},
    {"name": "Sprite", "symbols": ["SpritePixels"], "postprocess": id},
    {"name": "Sprite", "symbols": ["SpriteNoPixels"], "postprocess": id},
    {"name": "SpriteNoPixels$ebnf$1$subexpression$1", "symbols": ["__", "legendShortcutChar"]},
    {"name": "SpriteNoPixels$ebnf$1", "symbols": ["SpriteNoPixels$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "SpriteNoPixels$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "SpriteNoPixels$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "SpriteNoPixels$ebnf$2", "symbols": ["SpriteNoPixels$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SpriteNoPixels$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "SpriteNoPixels$ebnf$3", "symbols": ["SpriteNoPixels$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SpriteNoPixels", "symbols": ["_", "spriteName", "SpriteNoPixels$ebnf$1", "SpriteNoPixels$ebnf$2", "_", "colorDefinitions", "SpriteNoPixels$ebnf$3"], "postprocess": toDebug('SpriteNoPixels') || function ([_0, name, mapCharOpt, _2, _3, colors, _5], sourceOffset) { return {type: 'SPRITE_NO_PIXELS', name: name, mapChar: mapCharOpt ? mapCharOpt[1] : null, colors, sourceOffset} }},
    {"name": "SpritePixels$ebnf$1$subexpression$1", "symbols": ["__", "legendShortcutChar"]},
    {"name": "SpritePixels$ebnf$1", "symbols": ["SpritePixels$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "SpritePixels$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "SpritePixels$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "SpritePixels$ebnf$2", "symbols": ["SpritePixels$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SpritePixels$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "SpritePixels$ebnf$3", "symbols": ["SpritePixels$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SpritePixels$ebnf$4", "symbols": []},
    {"name": "SpritePixels$ebnf$4", "symbols": ["SpritePixels$ebnf$4", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SpritePixels", "symbols": ["_", "spriteName", "SpritePixels$ebnf$1", "SpritePixels$ebnf$2", "_", "colorDefinitions", "SpritePixels$ebnf$3", "PixelRows", "SpritePixels$ebnf$4"], "postprocess": toDebug('SpritePixels') || function ([_0, name, mapCharOpt, _2, _3, colors, _5, pixels, _7], sourceOffset) { return {type: 'SPRITE_WITH_PIXELS', name: name, mapChar: mapCharOpt ? mapCharOpt[1] : null, colors, pixels, sourceOffset} }},
    {"name": "colorDefinitions$macrocall$2", "symbols": ["colorNameOrHex"]},
    {"name": "colorDefinitions$macrocall$3", "symbols": ["__"]},
    {"name": "colorDefinitions$macrocall$1$ebnf$1", "symbols": []},
    {"name": "colorDefinitions$macrocall$1$ebnf$1$subexpression$1", "symbols": ["colorDefinitions$macrocall$3", "colorDefinitions$macrocall$2"]},
    {"name": "colorDefinitions$macrocall$1$ebnf$1", "symbols": ["colorDefinitions$macrocall$1$ebnf$1", "colorDefinitions$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "colorDefinitions$macrocall$1", "symbols": ["colorDefinitions$macrocall$2", "colorDefinitions$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "colorDefinitions", "symbols": ["colorDefinitions$macrocall$1"], "postprocess": ([a]) => extractFirst(a)},
    {"name": "colorDefinitions$macrocall$5", "symbols": ["colorHex6"]},
    {"name": "colorDefinitions$macrocall$6", "symbols": ["__"]},
    {"name": "colorDefinitions$macrocall$4$ebnf$1", "symbols": []},
    {"name": "colorDefinitions$macrocall$4$ebnf$1$subexpression$1", "symbols": ["colorDefinitions$macrocall$6", "colorDefinitions$macrocall$5"]},
    {"name": "colorDefinitions$macrocall$4$ebnf$1", "symbols": ["colorDefinitions$macrocall$4$ebnf$1", "colorDefinitions$macrocall$4$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "colorDefinitions$macrocall$4", "symbols": ["colorDefinitions$macrocall$5", "colorDefinitions$macrocall$4$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "colorDefinitions$macrocall$8", "symbols": ["colorHex6"]},
    {"name": "colorDefinitions$macrocall$9", "symbols": ["__"]},
    {"name": "colorDefinitions$macrocall$7$ebnf$1", "symbols": []},
    {"name": "colorDefinitions$macrocall$7$ebnf$1$subexpression$1", "symbols": ["colorDefinitions$macrocall$9", "colorDefinitions$macrocall$8"]},
    {"name": "colorDefinitions$macrocall$7$ebnf$1", "symbols": ["colorDefinitions$macrocall$7$ebnf$1", "colorDefinitions$macrocall$7$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "colorDefinitions$macrocall$7", "symbols": ["colorDefinitions$macrocall$8", "colorDefinitions$macrocall$7$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "colorDefinitions", "symbols": ["colorDefinitions$macrocall$4", "colorDefinitions$macrocall$7"], "postprocess": ([a, b]) => extractFirst(a.concat(b))},
    {"name": "spriteName", "symbols": ["ruleVariableName"], "postprocess": id},
    {"name": "pixelRow$ebnf$1", "symbols": ["pixelDigit"]},
    {"name": "pixelRow$ebnf$1", "symbols": ["pixelRow$ebnf$1", "pixelDigit"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "pixelRow", "symbols": ["_", "pixelRow$ebnf$1", "lineTerminator"], "postprocess": toDebug('pixelRow', function ([_0, entries, _2]) { return {type: 'PIXEL_ROW', entries: entries} }) || function ([_0, entries, _2]) { return entries }},
    {"name": "pixelDigit", "symbols": ["digit"], "postprocess": id},
    {"name": "pixelDigit", "symbols": [{"literal":"."}], "postprocess": id},
    {"name": "legendShortcutChar", "symbols": [/[^\n ]/], "postprocess": id},
    {"name": "PixelRows$ebnf$1", "symbols": ["pixelRow"]},
    {"name": "PixelRows$ebnf$1", "symbols": ["PixelRows$ebnf$1", "pixelRow"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "PixelRows", "symbols": ["pixelRow", "pixelRow", "pixelRow", "pixelRow", "PixelRows$ebnf$1"], "postprocess": ([r1, r2, r3, r4, rest]) => [r1, r2, r3, r4].concat(rest)},
    {"name": "LegendTile", "symbols": ["LegendTileSimple"], "postprocess": id},
    {"name": "LegendTile", "symbols": ["LegendTileAnd"], "postprocess": id},
    {"name": "LegendTile", "symbols": ["LegendTileOr"], "postprocess": id},
    {"name": "LegendTileSimple$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "LegendTileSimple$ebnf$1", "symbols": ["LegendTileSimple$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LegendTileSimple", "symbols": ["_", "LegendVarNameDefn", "_", {"literal":"="}, "_", "LookupLegendVarName", "LegendTileSimple$ebnf$1"], "postprocess": toDebug('LegendTileSimple') || function([_0, name, _2, _3, _4, value, _6, _7], sourceOffset) { return {type: 'LEGEND_ITEM_SIMPLE', name, value, sourceOffset} }},
    {"name": "LegendTileAnd$macrocall$2", "symbols": ["LookupLegendVarName"]},
    {"name": "LegendTileAnd$macrocall$3", "symbols": ["__", "t_AND", "__"]},
    {"name": "LegendTileAnd$macrocall$1$ebnf$1$subexpression$1", "symbols": ["LegendTileAnd$macrocall$3", "LegendTileAnd$macrocall$2"]},
    {"name": "LegendTileAnd$macrocall$1$ebnf$1", "symbols": ["LegendTileAnd$macrocall$1$ebnf$1$subexpression$1"]},
    {"name": "LegendTileAnd$macrocall$1$ebnf$1$subexpression$2", "symbols": ["LegendTileAnd$macrocall$3", "LegendTileAnd$macrocall$2"]},
    {"name": "LegendTileAnd$macrocall$1$ebnf$1", "symbols": ["LegendTileAnd$macrocall$1$ebnf$1", "LegendTileAnd$macrocall$1$ebnf$1$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LegendTileAnd$macrocall$1", "symbols": ["LegendTileAnd$macrocall$2", "LegendTileAnd$macrocall$1$ebnf$1"], "postprocess": toDebug('atLeast2ListOf') || nonemptyListOf},
    {"name": "LegendTileAnd$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "LegendTileAnd$ebnf$1", "symbols": ["LegendTileAnd$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LegendTileAnd", "symbols": ["_", "LegendVarNameDefn", "_", {"literal":"="}, "_", "LegendTileAnd$macrocall$1", "LegendTileAnd$ebnf$1"], "postprocess": toDebug('LegendTileAnd') || function([_0, name, _2, _3, _4, values, _6, _7], sourceOffset) { return {type: 'LEGEND_ITEM_AND', name, values: extractFirst(values), sourceOffset} }},
    {"name": "LegendTileOr$macrocall$2", "symbols": ["LookupLegendVarName"]},
    {"name": "LegendTileOr$macrocall$3", "symbols": ["__", "t_OR", "__"]},
    {"name": "LegendTileOr$macrocall$1$ebnf$1$subexpression$1", "symbols": ["LegendTileOr$macrocall$3", "LegendTileOr$macrocall$2"]},
    {"name": "LegendTileOr$macrocall$1$ebnf$1", "symbols": ["LegendTileOr$macrocall$1$ebnf$1$subexpression$1"]},
    {"name": "LegendTileOr$macrocall$1$ebnf$1$subexpression$2", "symbols": ["LegendTileOr$macrocall$3", "LegendTileOr$macrocall$2"]},
    {"name": "LegendTileOr$macrocall$1$ebnf$1", "symbols": ["LegendTileOr$macrocall$1$ebnf$1", "LegendTileOr$macrocall$1$ebnf$1$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LegendTileOr$macrocall$1", "symbols": ["LegendTileOr$macrocall$2", "LegendTileOr$macrocall$1$ebnf$1"], "postprocess": toDebug('atLeast2ListOf') || nonemptyListOf},
    {"name": "LegendTileOr$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "LegendTileOr$ebnf$1", "symbols": ["LegendTileOr$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LegendTileOr", "symbols": ["_", "LegendVarNameDefn", "_", {"literal":"="}, "_", "LegendTileOr$macrocall$1", "LegendTileOr$ebnf$1"], "postprocess": toDebug('LegendTileOr')  || function([_0, name, _2, _3, _4, values, _6, _7], sourceOffset) { return {type: 'LEGEND_ITEM_OR', name, values: extractFirst(values), sourceOffset} }},
    {"name": "LegendVarNameDefn", "symbols": ["word"], "postprocess": toDebug('LegendVarNameDefn') || id},
    {"name": "LookupLegendVarName", "symbols": ["LegendVarNameDefn"], "postprocess": toDebug('LookupLegendVarName') || id},
    {"name": "SoundItem$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "SoundItem$ebnf$1", "symbols": ["SoundItem$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "SoundItem", "symbols": ["_", "SoundItemInner", "SoundItem$ebnf$1"], "postprocess": ([_0, sound, _2]) => sound},
    {"name": "SoundItemInner", "symbols": ["SoundItemEnum"], "postprocess": id},
    {"name": "SoundItemInner", "symbols": ["SoundItemSfx"], "postprocess": id},
    {"name": "SoundItemInner", "symbols": ["SoundItemMoveDirection"], "postprocess": id},
    {"name": "SoundItemInner", "symbols": ["SoundItemMoveSimple"], "postprocess": id},
    {"name": "SoundItemInner", "symbols": ["SoundItemNormal"], "postprocess": id},
    {"name": "soundItemSimpleOptions", "symbols": ["t_RESTART"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_UNDO"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_TITLESCREEN"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_STARTGAME"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_STARTLEVEL"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_ENDLEVEL"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_ENDGAME"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_SHOWMESSAGE"], "postprocess": upperId},
    {"name": "soundItemSimpleOptions", "symbols": ["t_CLOSEMESSAGE"], "postprocess": upperId},
    {"name": "SoundItemEnum", "symbols": ["soundItemSimpleOptions", "__", "integer"], "postprocess": ([when, _1, soundCode], sourceOffset) => { return {type: 'SOUND_WHEN', when, soundCode, sourceOffset} }},
    {"name": "SoundItemSfx", "symbols": ["t_SFX", "__", "integer"], "postprocess": ([sfx, _1, soundCode], sourceOffset) => { return {type: 'SOUND_SFX', sfx, soundCode, sourceOffset} }},
    {"name": "SoundItemMoveDirection", "symbols": ["lookupRuleVariableName", "__", "t_MOVE", "__", "soundItemActionMoveArg", "__", "integer"], "postprocess": ([sprite, _1, _2, _3, direction, _5, soundCode], sourceOffset) => { return {type: 'SOUND_SPRITE_DIRECTION', sprite, direction, soundCode, sourceOffset} }},
    {"name": "SoundItemMoveSimple", "symbols": ["lookupRuleVariableName", "__", "t_MOVE", "__", "integer"], "postprocess": ([sprite, _1, _2, _3, soundCode], sourceOffset) => { return {type: 'SOUND_SPRITE_MOVE', sprite, soundCode, sourceOffset} }},
    {"name": "SoundItemNormal", "symbols": ["lookupRuleVariableName", "__", "SoundItemAction", "__", "integer"], "postprocess": ([sprite, _1, eventEnum, _3, soundCode], sourceOffset) => { return {type: 'SOUND_SPRITE_EVENT', sprite, eventEnum, soundCode, sourceOffset} }},
    {"name": "SoundItemAction", "symbols": ["t_CREATE"], "postprocess": upperId},
    {"name": "SoundItemAction", "symbols": ["t_DESTROY"], "postprocess": upperId},
    {"name": "SoundItemAction", "symbols": ["t_CANTMOVE"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_UP"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_DOWN"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_LEFT"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_RIGHT"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_HORIZONTAL"], "postprocess": upperId},
    {"name": "soundItemActionMoveArg", "symbols": ["t_VERTICAL"], "postprocess": upperId},
    {"name": "CollisionLayerItem$macrocall$2", "symbols": ["lookupCollisionVariableName"]},
    {"name": "CollisionLayerItem$macrocall$3$subexpression$1", "symbols": ["_", {"literal":","}, "_"]},
    {"name": "CollisionLayerItem$macrocall$3$subexpression$1", "symbols": ["__"]},
    {"name": "CollisionLayerItem$macrocall$3", "symbols": ["CollisionLayerItem$macrocall$3$subexpression$1"]},
    {"name": "CollisionLayerItem$macrocall$1$ebnf$1", "symbols": []},
    {"name": "CollisionLayerItem$macrocall$1$ebnf$1$subexpression$1", "symbols": ["CollisionLayerItem$macrocall$3", "CollisionLayerItem$macrocall$2"]},
    {"name": "CollisionLayerItem$macrocall$1$ebnf$1", "symbols": ["CollisionLayerItem$macrocall$1$ebnf$1", "CollisionLayerItem$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "CollisionLayerItem$macrocall$1", "symbols": ["CollisionLayerItem$macrocall$2", "CollisionLayerItem$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "CollisionLayerItem$ebnf$1", "symbols": [{"literal":","}], "postprocess": id},
    {"name": "CollisionLayerItem$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "CollisionLayerItem$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "CollisionLayerItem$ebnf$2", "symbols": ["CollisionLayerItem$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "CollisionLayerItem", "symbols": ["_", "CollisionLayerItem$macrocall$1", "CollisionLayerItem$ebnf$1", "CollisionLayerItem$ebnf$2"], "postprocess": toDebug('CollisionLayerItem') || function ([_0, spriteNames, _2], sourceOffset) { return {type: 'COLLISION_LAYER', tiles: extractFirst(spriteNames), sourceOffset} }},
    {"name": "RuleItem", "symbols": ["RuleLoop"], "postprocess": id},
    {"name": "RuleItem", "symbols": ["RuleGroup"], "postprocess": id},
    {"name": "RuleItem", "symbols": ["Rule"], "postprocess": id},
    {"name": "Rule", "symbols": ["RuleWithoutMessage"], "postprocess": id},
    {"name": "Rule", "symbols": ["RuleWithMessage"], "postprocess": id},
    {"name": "RuleWithoutMessage$macrocall$2", "symbols": ["ConditionBracket"]},
    {"name": "RuleWithoutMessage$macrocall$3$ebnf$1$subexpression$1", "symbols": ["RuleModifier", "_"]},
    {"name": "RuleWithoutMessage$macrocall$3$ebnf$1", "symbols": ["RuleWithoutMessage$macrocall$3$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "RuleWithoutMessage$macrocall$3$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "RuleWithoutMessage$macrocall$3", "symbols": ["_", "RuleWithoutMessage$macrocall$3$ebnf$1"]},
    {"name": "RuleWithoutMessage$macrocall$1$ebnf$1", "symbols": []},
    {"name": "RuleWithoutMessage$macrocall$1$ebnf$1$subexpression$1", "symbols": ["RuleWithoutMessage$macrocall$3", "RuleWithoutMessage$macrocall$2"]},
    {"name": "RuleWithoutMessage$macrocall$1$ebnf$1", "symbols": ["RuleWithoutMessage$macrocall$1$ebnf$1", "RuleWithoutMessage$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithoutMessage$macrocall$1", "symbols": ["RuleWithoutMessage$macrocall$2", "RuleWithoutMessage$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "RuleWithoutMessage$string$1", "symbols": [{"literal":"-"}, {"literal":">"}], "postprocess": (d) => d.join('')},
    {"name": "RuleWithoutMessage$ebnf$1", "symbols": []},
    {"name": "RuleWithoutMessage$ebnf$1$subexpression$1", "symbols": ["ActionBracket"]},
    {"name": "RuleWithoutMessage$ebnf$1", "symbols": ["RuleWithoutMessage$ebnf$1", "RuleWithoutMessage$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithoutMessage$ebnf$2", "symbols": []},
    {"name": "RuleWithoutMessage$ebnf$2$subexpression$1", "symbols": ["_", "RuleCommand"]},
    {"name": "RuleWithoutMessage$ebnf$2", "symbols": ["RuleWithoutMessage$ebnf$2", "RuleWithoutMessage$ebnf$2$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithoutMessage$ebnf$3", "symbols": ["lineTerminator"]},
    {"name": "RuleWithoutMessage$ebnf$3", "symbols": ["RuleWithoutMessage$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithoutMessage", "symbols": ["_", "LeftModifiers", "RuleWithoutMessage$macrocall$1", "_", "RuleWithoutMessage$string$1", "RuleWithoutMessage$ebnf$1", "RuleWithoutMessage$ebnf$2", "RuleWithoutMessage$ebnf$3"], "postprocess": toDebug('RuleWithoutMessage') || function([_0, modifiers, conditionBrackets, _2, _3, actionBrackets, commands, _6], sourceOffset)                 { return {type: 'RULE', modifiers, conditions: extractFirst(conditionBrackets), actions: extractFirst(actionBrackets), commands: extractSecond(commands), sourceOffset} }},
    {"name": "RuleWithMessage$macrocall$2", "symbols": ["ConditionBracket"]},
    {"name": "RuleWithMessage$macrocall$3$ebnf$1$subexpression$1", "symbols": ["RuleModifier", "_"]},
    {"name": "RuleWithMessage$macrocall$3$ebnf$1", "symbols": ["RuleWithMessage$macrocall$3$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "RuleWithMessage$macrocall$3$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "RuleWithMessage$macrocall$3", "symbols": ["_", "RuleWithMessage$macrocall$3$ebnf$1"]},
    {"name": "RuleWithMessage$macrocall$1$ebnf$1", "symbols": []},
    {"name": "RuleWithMessage$macrocall$1$ebnf$1$subexpression$1", "symbols": ["RuleWithMessage$macrocall$3", "RuleWithMessage$macrocall$2"]},
    {"name": "RuleWithMessage$macrocall$1$ebnf$1", "symbols": ["RuleWithMessage$macrocall$1$ebnf$1", "RuleWithMessage$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithMessage$macrocall$1", "symbols": ["RuleWithMessage$macrocall$2", "RuleWithMessage$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "RuleWithMessage$string$1", "symbols": [{"literal":"-"}, {"literal":">"}], "postprocess": (d) => d.join('')},
    {"name": "RuleWithMessage$ebnf$1", "symbols": []},
    {"name": "RuleWithMessage$ebnf$1$subexpression$1", "symbols": ["ActionBracket"]},
    {"name": "RuleWithMessage$ebnf$1", "symbols": ["RuleWithMessage$ebnf$1", "RuleWithMessage$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithMessage$ebnf$2", "symbols": []},
    {"name": "RuleWithMessage$ebnf$2$subexpression$1", "symbols": ["_", "RuleCommand"]},
    {"name": "RuleWithMessage$ebnf$2", "symbols": ["RuleWithMessage$ebnf$2", "RuleWithMessage$ebnf$2$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithMessage$ebnf$3", "symbols": []},
    {"name": "RuleWithMessage$ebnf$3", "symbols": ["RuleWithMessage$ebnf$3", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleWithMessage", "symbols": ["_", "LeftModifiers", "RuleWithMessage$macrocall$1", "_", "RuleWithMessage$string$1", "RuleWithMessage$ebnf$1", "RuleWithMessage$ebnf$2", "_", "MessageCommand", "RuleWithMessage$ebnf$3"], "postprocess": toDebug('RuleWithoutMessage') || function([_0, modifiers, conditionBrackets, _2, _3, actionBrackets, commands, _6, message, _7], sourceOffset)    { return {type: 'RULE', modifiers, conditions: extractFirst(conditionBrackets), actions: extractFirst(actionBrackets), commands: extractSecond(commands), message, sourceOffset} }},
    {"name": "ConditionBracket", "symbols": ["NormalRuleBracket"], "postprocess": ([{neighbors, againHack, debugFlag}], sourceOffset) => { return {type:'BRACKET', neighbors, againHack, debugFlag, sourceOffset} }},
    {"name": "ConditionBracket", "symbols": ["EllipsisRuleBracket"], "postprocess": ([{beforeNeighbors, afterNeighbors, debugFlag}], sourceOffset) => { return {type:'ELLIPSIS_BRACKET', beforeNeighbors, afterNeighbors, debugFlag, sourceOffset} }},
    {"name": "ActionBracket$ebnf$1", "symbols": []},
    {"name": "ActionBracket$ebnf$1$subexpression$1", "symbols": ["_", "RuleModifier"]},
    {"name": "ActionBracket$ebnf$1", "symbols": ["ActionBracket$ebnf$1", "ActionBracket$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "ActionBracket", "symbols": ["ActionBracket$ebnf$1", "_", "NormalRuleBracket"], "postprocess": ([modifiers, _1, {neighbors, againHack, debugFlag}], sourceOffset) => { return {type:'BRACKET', neighbors, againHack, debugFlag, sourceOffset} }},
    {"name": "ActionBracket$ebnf$2", "symbols": []},
    {"name": "ActionBracket$ebnf$2$subexpression$1", "symbols": ["_", "RuleModifier"]},
    {"name": "ActionBracket$ebnf$2", "symbols": ["ActionBracket$ebnf$2", "ActionBracket$ebnf$2$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "ActionBracket", "symbols": ["ActionBracket$ebnf$2", "_", "EllipsisRuleBracket"], "postprocess": ([modifiers, _1, {beforeNeighbors, afterNeighbors, debugFlag}], sourceOffset) => { return {type:'ELLIPSIS_BRACKET', beforeNeighbors, afterNeighbors, debugFlag, sourceOffset} }},
    {"name": "LeftModifiers$macrocall$2", "symbols": ["RuleModifierLeft"]},
    {"name": "LeftModifiers$macrocall$3", "symbols": ["__"]},
    {"name": "LeftModifiers$macrocall$1$ebnf$1", "symbols": []},
    {"name": "LeftModifiers$macrocall$1$ebnf$1$subexpression$1", "symbols": ["LeftModifiers$macrocall$3", "LeftModifiers$macrocall$2"]},
    {"name": "LeftModifiers$macrocall$1$ebnf$1", "symbols": ["LeftModifiers$macrocall$1$ebnf$1", "LeftModifiers$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LeftModifiers$macrocall$1", "symbols": ["LeftModifiers$macrocall$2", "LeftModifiers$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "LeftModifiers", "symbols": ["LeftModifiers$macrocall$1", "_"], "postprocess": ([a]) => extractFirst(a)},
    {"name": "LeftModifiers", "symbols": [], "postprocess": () => [] /* No modifiers */},
    {"name": "RuleBracket", "symbols": ["EllipsisRuleBracket"], "postprocess": id},
    {"name": "RuleBracket", "symbols": ["NormalRuleBracket"], "postprocess": id},
    {"name": "NormalRuleBracket$macrocall$2", "symbols": ["RuleBracketNeighbor"]},
    {"name": "NormalRuleBracket$macrocall$3", "symbols": [{"literal":"|"}]},
    {"name": "NormalRuleBracket$macrocall$1$ebnf$1", "symbols": []},
    {"name": "NormalRuleBracket$macrocall$1$ebnf$1$subexpression$1", "symbols": ["NormalRuleBracket$macrocall$3", "NormalRuleBracket$macrocall$2"]},
    {"name": "NormalRuleBracket$macrocall$1$ebnf$1", "symbols": ["NormalRuleBracket$macrocall$1$ebnf$1", "NormalRuleBracket$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "NormalRuleBracket$macrocall$1", "symbols": ["NormalRuleBracket$macrocall$2", "NormalRuleBracket$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "NormalRuleBracket$ebnf$1$subexpression$1", "symbols": ["t_AGAIN", "_"]},
    {"name": "NormalRuleBracket$ebnf$1", "symbols": ["NormalRuleBracket$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "NormalRuleBracket$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "NormalRuleBracket$ebnf$2$subexpression$1", "symbols": ["_", "t_DEBUGGER"]},
    {"name": "NormalRuleBracket$ebnf$2", "symbols": ["NormalRuleBracket$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "NormalRuleBracket$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "NormalRuleBracket", "symbols": [{"literal":"["}, "NormalRuleBracket$macrocall$1", "NormalRuleBracket$ebnf$1", {"literal":"]"}, "NormalRuleBracket$ebnf$2"], "postprocess": toDebug('NormalRuleBracket') || function([_0, neighbors, againHack, _3, debugFlag], sourceOffset) { return {type: '_INNER_BRACKET', neighbors: extractFirst(neighbors), againHack: againHack ? true : false, debugFlag: debugFlag ? debugFlag[1] : null, sourceOffset} }},
    {"name": "EllipsisRuleBracket$macrocall$2", "symbols": ["RuleBracketNeighbor"]},
    {"name": "EllipsisRuleBracket$macrocall$3", "symbols": [{"literal":"|"}]},
    {"name": "EllipsisRuleBracket$macrocall$1$ebnf$1", "symbols": []},
    {"name": "EllipsisRuleBracket$macrocall$1$ebnf$1$subexpression$1", "symbols": ["EllipsisRuleBracket$macrocall$3", "EllipsisRuleBracket$macrocall$2"]},
    {"name": "EllipsisRuleBracket$macrocall$1$ebnf$1", "symbols": ["EllipsisRuleBracket$macrocall$1$ebnf$1", "EllipsisRuleBracket$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "EllipsisRuleBracket$macrocall$1", "symbols": ["EllipsisRuleBracket$macrocall$2", "EllipsisRuleBracket$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "EllipsisRuleBracket$macrocall$5", "symbols": ["RuleBracketNeighbor"]},
    {"name": "EllipsisRuleBracket$macrocall$6", "symbols": [{"literal":"|"}]},
    {"name": "EllipsisRuleBracket$macrocall$4$ebnf$1", "symbols": []},
    {"name": "EllipsisRuleBracket$macrocall$4$ebnf$1$subexpression$1", "symbols": ["EllipsisRuleBracket$macrocall$6", "EllipsisRuleBracket$macrocall$5"]},
    {"name": "EllipsisRuleBracket$macrocall$4$ebnf$1", "symbols": ["EllipsisRuleBracket$macrocall$4$ebnf$1", "EllipsisRuleBracket$macrocall$4$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "EllipsisRuleBracket$macrocall$4", "symbols": ["EllipsisRuleBracket$macrocall$5", "EllipsisRuleBracket$macrocall$4$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "EllipsisRuleBracket$ebnf$1$subexpression$1", "symbols": ["_", "t_DEBUGGER"]},
    {"name": "EllipsisRuleBracket$ebnf$1", "symbols": ["EllipsisRuleBracket$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "EllipsisRuleBracket$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "EllipsisRuleBracket", "symbols": [{"literal":"["}, "EllipsisRuleBracket$macrocall$1", {"literal":"|"}, "_", "t_ELLIPSIS", "_", {"literal":"|"}, "EllipsisRuleBracket$macrocall$4", {"literal":"]"}, "EllipsisRuleBracket$ebnf$1"], "postprocess": toDebug('EllipsisRuleBracket') || function([_0, beforeNeighbors, _2, _3, _4, _5, _6, afterNeighbors, _8, debugFlag], sourceOffset) { return {type: '_INNER_ELLIPSIS_BRACKET', beforeNeighbors: extractFirst(beforeNeighbors), afterNeighbors: extractFirst(afterNeighbors), debugFlag: debugFlag ? debugFlag[1] : null, sourceOffset} }},
    {"name": "RuleBracketNeighbor", "symbols": ["RuleBracketNoEllipsisNeighbor"], "postprocess": id},
    {"name": "RuleBracketNeighbor", "symbols": ["RuleBracketEmptyNeighbor"], "postprocess": id},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$2", "symbols": ["TileWithModifier"]},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$3", "symbols": ["__"]},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1", "symbols": []},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1$subexpression$1", "symbols": ["RuleBracketNoEllipsisNeighbor$macrocall$3", "RuleBracketNoEllipsisNeighbor$macrocall$2"]},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1", "symbols": ["RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1", "RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleBracketNoEllipsisNeighbor$macrocall$1", "symbols": ["RuleBracketNoEllipsisNeighbor$macrocall$2", "RuleBracketNoEllipsisNeighbor$macrocall$1$ebnf$1"], "postprocess": toDebug('nonemptyListOf') || nonemptyListOf},
    {"name": "RuleBracketNoEllipsisNeighbor$ebnf$1$subexpression$1", "symbols": ["_", "t_DEBUGGER"]},
    {"name": "RuleBracketNoEllipsisNeighbor$ebnf$1", "symbols": ["RuleBracketNoEllipsisNeighbor$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "RuleBracketNoEllipsisNeighbor$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "RuleBracketNoEllipsisNeighbor", "symbols": ["_", "RuleBracketNoEllipsisNeighbor$macrocall$1", "RuleBracketNoEllipsisNeighbor$ebnf$1", "_"], "postprocess": toDebug('RuleBracketNoEllipsisNeighbor') || function([_0, tilesWithModifier, debugFlag, _3], sourceOffset) { return {type: 'NEIGHBOR', tilesWithModifier: extractFirst(tilesWithModifier), debugFlag: debugFlag ? debugFlag[1] : null, sourceOffset} }},
    {"name": "RuleBracketEmptyNeighbor", "symbols": ["_"], "postprocess": toDebug('RuleBracketEmptyNeighbor') || function([_0], sourceOffset) { return {type: 'NEIGHBOR', tilesWithModifier: [], sourceOffset} }},
    {"name": "TileWithModifier$ebnf$1$subexpression$1", "symbols": ["tileModifier", "__"]},
    {"name": "TileWithModifier$ebnf$1", "symbols": ["TileWithModifier$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "TileWithModifier$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "TileWithModifier", "symbols": ["TileWithModifier$ebnf$1", "lookupRuleVariableName"], "postprocess": toDebug('TileWithModifier') || function([modifier, tile], sourceOffset) { return {type: 'TILE_WITH_MODIFIER', modifier: modifier ? modifier[0] : null, tile, sourceOffset} }},
    {"name": "tileModifier", "symbols": ["t_NO"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_LEFT"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_RIGHT"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_UP"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_DOWN"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_RANDOMDIR"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_RANDOM"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_STATIONARY"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_MOVING"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_ACTION"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_VERTICAL"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_HORIZONTAL"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_PERPENDICULAR"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_PARALLEL"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_ORTHOGONAL"], "postprocess": upperId},
    {"name": "tileModifier", "symbols": ["t_ARROW_ANY"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_RANDOM"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_UP"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_DOWN"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_LEFT"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_RIGHT"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_VERTICAL"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_HORIZONTAL"], "postprocess": upperId},
    {"name": "RuleModifier", "symbols": ["t_ORTHOGONAL"], "postprocess": upperId},
    {"name": "RuleModifierLeft", "symbols": ["RuleModifier"], "postprocess": id},
    {"name": "RuleModifierLeft", "symbols": ["t_LATE"], "postprocess": upperId},
    {"name": "RuleModifierLeft", "symbols": ["t_RIGID"], "postprocess": upperId},
    {"name": "RuleCommand", "symbols": ["t_AGAIN"], "postprocess": (_0, sourceOffset) => { return {type: 'COMMAND_AGAIN', sourceOffset} }},
    {"name": "RuleCommand", "symbols": ["t_CANCEL"], "postprocess": (_0, sourceOffset) => { return {type: 'COMMAND_CANCEL', sourceOffset} }},
    {"name": "RuleCommand", "symbols": ["t_CHECKPOINT"], "postprocess": (_0, sourceOffset) => { return {type: 'COMMAND_CHECKPOINT', sourceOffset} }},
    {"name": "RuleCommand", "symbols": ["t_RESTART"], "postprocess": (_0, sourceOffset) => { return {type: 'COMMAND_RESTART', sourceOffset} }},
    {"name": "RuleCommand", "symbols": ["t_WIN"], "postprocess": (_0, sourceOffset) => { return {type: 'COMMAND_WIN', sourceOffset} }},
    {"name": "RuleCommand", "symbols": ["t_SFX"], "postprocess": ([sfx], sourceOffset) => { return {type: 'COMMAND_SFX', sfx, sourceOffset} }},
    {"name": "MessageCommand", "symbols": ["t_MESSAGE", "messageLine"], "postprocess": ([_1, message], sourceOffset) => { return {type:'COMMAND_MESSAGE', message, sourceOffset} }},
    {"name": "RuleLoop$ebnf$1$subexpression$1", "symbols": ["_", "t_DEBUGGER"]},
    {"name": "RuleLoop$ebnf$1", "symbols": ["RuleLoop$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "RuleLoop$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "RuleLoop$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "RuleLoop$ebnf$2", "symbols": ["RuleLoop$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleLoop$ebnf$3$subexpression$1", "symbols": ["RuleItem"]},
    {"name": "RuleLoop$ebnf$3", "symbols": ["RuleLoop$ebnf$3$subexpression$1"]},
    {"name": "RuleLoop$ebnf$3$subexpression$2", "symbols": ["RuleItem"]},
    {"name": "RuleLoop$ebnf$3", "symbols": ["RuleLoop$ebnf$3", "RuleLoop$ebnf$3$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleLoop$ebnf$4", "symbols": ["lineTerminator"]},
    {"name": "RuleLoop$ebnf$4", "symbols": ["RuleLoop$ebnf$4", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleLoop", "symbols": ["RuleLoop$ebnf$1", "_", "t_STARTLOOP", "RuleLoop$ebnf$2", "RuleLoop$ebnf$3", "_", "t_ENDLOOP", "RuleLoop$ebnf$4"], "postprocess": ([_0, _1, _2, _3, rules, _4, _5, _6]) => { return {type:'RULE_LOOP', rules: extractFirst(rules)} }},
    {"name": "RuleGroup$ebnf$1$subexpression$1", "symbols": ["_", "t_GROUP_RULE_PLUS", "Rule"]},
    {"name": "RuleGroup$ebnf$1", "symbols": ["RuleGroup$ebnf$1$subexpression$1"]},
    {"name": "RuleGroup$ebnf$1$subexpression$2", "symbols": ["_", "t_GROUP_RULE_PLUS", "Rule"]},
    {"name": "RuleGroup$ebnf$1", "symbols": ["RuleGroup$ebnf$1", "RuleGroup$ebnf$1$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "RuleGroup", "symbols": ["Rule", "RuleGroup$ebnf$1"], "postprocess": ([firstRule, otherRules], sourceOffset) => { return {type:'RULE_GROUP', rules: [firstRule].concat(extractThird(otherRules)), sourceOffset} }},
    {"name": "WinConditionItem$ebnf$1", "symbols": ["lineTerminator"]},
    {"name": "WinConditionItem$ebnf$1", "symbols": ["WinConditionItem$ebnf$1", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "WinConditionItem", "symbols": ["_", "winConditionItemPrefix", "__", "lookupRuleVariableName", "WinConditionItem$ebnf$1"], "postprocess": toDebug('WinConditionItem') || function([_0, qualifier, _1, sprite, _2], sourceOffset) { return {type: 'WINCONDITION_SIMPLE', qualifier, sprite, sourceOffset} }},
    {"name": "WinConditionItem$ebnf$2", "symbols": ["lineTerminator"]},
    {"name": "WinConditionItem$ebnf$2", "symbols": ["WinConditionItem$ebnf$2", "lineTerminator"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "WinConditionItem", "symbols": ["_", "winConditionItemPrefix", "__", "lookupRuleVariableName", "__", "t_ON", "__", "lookupRuleVariableName", "WinConditionItem$ebnf$2"], "postprocess": toDebug('WinConditionItem') || function([_0, qualifier, _1, sprite, _2, _3, _4, onSprite, _5], sourceOffset) { return {type: 'WINCONDITION_ON', qualifier, sprite, onSprite, sourceOffset} }},
    {"name": "winConditionItemPrefix", "symbols": ["t_NO"], "postprocess": id},
    {"name": "winConditionItemPrefix", "symbols": ["t_ALL"], "postprocess": id},
    {"name": "winConditionItemPrefix", "symbols": ["t_ANY"], "postprocess": id},
    {"name": "winConditionItemPrefix", "symbols": ["t_SOME"], "postprocess": id},
    {"name": "LevelItem", "symbols": ["GameMessageLevel"], "postprocess": id},
    {"name": "LevelItem", "symbols": ["levelMapRow"], "postprocess": id},
    {"name": "LevelItem", "symbols": ["SeparatorLine"], "postprocess": id},
    {"name": "GameMessageLevel", "symbols": ["_", "t_MESSAGE", "messageLine"], "postprocess": ([_0, _1, message], sourceOffset) => { return {type: 'LEVEL_MESSAGE', message, sourceOffset} }},
    {"name": "messageLine$ebnf$1", "symbols": []},
    {"name": "messageLine$ebnf$1", "symbols": ["messageLine$ebnf$1", /[^\n]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "messageLine", "symbols": ["messageLine$ebnf$1", /[\n]/], "postprocess": toDebug('messageLine') || function([message, _2]) { return message.join('').trim() }},
    {"name": "levelMapRow$ebnf$1", "symbols": [/[^\n \t\(]/]},
    {"name": "levelMapRow$ebnf$1", "symbols": ["levelMapRow$ebnf$1", /[^\n \t\(]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "levelMapRow", "symbols": ["_", "levelMapRow$ebnf$1", "lineTerminator"], "postprocess":  ([_0, cols]: string[][], sourceOffset, reject) => {
          const str = cols.join('')
          if (str.toUpperCase().startsWith('MESSAGE')) {
            return reject
          } else {
            return {type: 'LEVEL_ROW', rowData: cols.map(([char]) => char[0]), sourceOffset}
          }
        }
        },
    {"name": "SeparatorLine", "symbols": ["lineTerminator"], "postprocess": (_0, sourceOffset) => { return {type:'LEVEL_SEPARATOR', sourceOffset} }}
];

export var ParserStart: string = "main";
